* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: black;
  font-family: monospace;
}

.container {
  z-index: -1;
}

.registry-nav {
  margin: 2rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registry-nav .link {
  color: white;
  text-decoration: none;
  padding: 1rem 2rem;
  letter-spacing: 2px;
  cursor: pointer;
  border: solid 2px rgb(255, 255, 255);
  font-size: x-large;
  font-weight: 400;
  letter-spacing: 5px;
}

.registry-nav .link:hover {
  
  color: DarkSlateGray !important;
  background: rgba(255, 255, 255, 1);
}

.registry-container {
  min-height: 100vh;
  width: 100%;
  max-width: 80ch;
  margin: auto;
  margin-bottom: 4rem;
  color: white;
  font-size: 1.2rem;
}

.registry-container h1, h2, p {
  margin: 0;
  padding: 1rem 2rem;
}

.registry-container h2 {
  font-weight: 700;
}


.opening {
  position: relative;
  object-fit: cover;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.opening-video {
  position: absolute;
  object-fit: cover;
  background-position: center center;
  background-size: cover;
  z-index: -1;
  width: 100%;
  height: 100vh;
  margin-top: 0;
}

.introduction {
  margin-top: 75vh;
  position: absolute;
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.opening-buttons-container {
  display: flex;
  max-width: 600px;
  margin: auto auto;
  justify-content: space-around;
  flex: 2;
}

.opening-button {
  width: 11rem;
  position: relative;
  display: inline-block;
  z-index: 2;
  padding: 1rem 2rem;
  color: rgb(255, 255, 255) !important;
  text-shadow: none;
  border: solid 2px rgb(255, 255, 255);
  border-radius: 0;
  background: rgba(255, 255, 255, 0);
  transition: all 0.3s;
  text-shadow: 0 2px 25px rgb(0 0 0);
  font-family: monospace;
  font-size: x-large;
  font-weight: 400;
  letter-spacing: 5px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.opening-button:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0);
  color: DarkSlateGray !important;
  background: rgba(255, 255, 255, 1);
}

.section {
  position: relative;
  object-fit: cover;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.background-container {
  object-fit: cover;
  background-position: center center;
  background-size: cover;
  position: absolute;
  height: 100vh;
  width: 100%;
  box-shadow: inset 0px 0px 10vw 8vw #000000;
}

.section-background {
  object-fit: cover;
  background-position: center center;
  background-size: cover;
  position: absolute;
  height: 100vh;
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(50%);
  filter: brightness(20%);
  z-index: -1;
}

.section-container {
  height: 100vh;
  width: 100%;
  max-width: 100ch;
  padding: 2rem;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.header-container {
  color: white;
  z-index: 1;
}

.header-container h2 {
  color: white;
  text-shadow: 0 2px 25px rgb(0 0 0);
  font-family: monospace;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 5px;
  text-align: center;
}

.text-container {
  margin: 0;
  position: relative;
  z-index: 1;
}

.text-container p {
  color: white;
  text-shadow: 0 2px 25px rgb(0 0 0);
  font-family: monospace;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 2px;
}

.link-container a {
  color: rgb(169, 229, 247);
  text-shadow: 0 2px 25px rgb(0 0 0);
  font-family: monospace;
  font-size: 1.2rem;
  font-weight: 900;
  letter-spacing: 2px;
  text-decoration: none;
}

.footer {
  position: relative;
  object-fit: cover;
  height: 20vh;
  width: 100%;
  z-index: 0;
}

.someIcons {
  height: 10vh;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.icon {
  margin: 30px 20px;
  font-size: 3rem;
  color: white;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.icon:hover{
  transform: translateY(-5px);
  color: whitesmoke;
}

html {
  scroll-behavior: smooth;
}

@media only screen and (max-width: 600px) {
  .opening-video {
    margin-top: -20vh;
  }

  .opening {
    font-size: 25px;
  }

  .introduction {
    margin-top: 55vh;
  }

  .opening-buttons-container {
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }

  .text-container p {
    font-size: 1.0rem;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .registry-container {
    font-size: 1rem;
  }

  .logo-image {
    height: 40px;
  }

  .registry-nav .link {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  }

}